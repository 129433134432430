<template>
  <!-- 公司官网 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <le-card title="公司官网">
      <template slot="heard-right">
        <lbButton type="warning" icon="bianji" @click="updateForm" v-if="isShowBtn('99c23ef30ee2406882c00be8')">保存</lbButton>
      </template>
      <el-form ref="addFormRef" :rules="addFormRef" :model="addForm" label-width="120px" class="addForm_box">
      <el-form-item label="公司官网：" prop="Content">
        <el-input v-model="addForm.Content" maxlength="100" placeholder="请输入公司官网" @input="(e) => (addForm.Content = validSpacing(e))"></el-input>
      </el-form-item>
    </el-form>
    </le-card>
  </div>
</template>
<script>
import { getMaintenanceMain, updateMaintenanceMain, addMaintenanceMain } from '@/api/maintenanceCenter'
export default {
  data () {
    return {
      addForm: {
        _id: '',
        // 类型  开发者 = 1, 小助手 = 2, 用户协议 = 3, 隐私政策 = 4, 咨询热线 = 5, 公司官网 = 6
        Type: 6,
        Content: ''
      },
      addFormRef: {
        Content: [
          { required: true, message: '请输入公司官网', trigger: 'blur' },
          { pattern: /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/, message: '请输入正确的网址', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.fnGetMaintenanceMain()
  },
  methods: {
    // 获取公司官网数据
    async fnGetMaintenanceMain () {
      const res = await getMaintenanceMain({
        type: 6
      })
      console.log('获取公司官网数据---', res)
      if (res.Code === 200 && res.Data && res.Data.length > 0) {
        // this.addForm = JSON.parse(JSON.stringify(res.Data[0]))
        this.addForm.Content = res.Data[0].content
        this.addForm._id = res.Data[0].id
        this.addForm.MenuName = '公司官网'
        this.addForm.Type = 6 // 类型  开发者 = 1, 小助手 = 2, 用户协议 = 3, 隐私政策 = 4, 咨询热线 = 5, 公司官网 = 6
      }
    },
    // 保存
    updateForm () {
      // 对整个表单进行校验
      this.$refs.addFormRef.validate((valid) => {
        // 成功
        if (valid) {
          if (this.addForm._id) {
            this.fnUpdateMaintenanceMain()
          } else {
            this.fnAddMaintenanceMain()
          }
        } else {
          return false
        }
      })
    },
    // 更新公司官网
    async fnUpdateMaintenanceMain () {
      const res = await updateMaintenanceMain(this.addForm)
      console.log('更新公司官网----', res)
      if (res.Code === 200) {
        this.$msg.success(res.Message)
        this.fnGetMaintenanceMain()
      }
    },
    // 新增公司官网
    async fnAddMaintenanceMain () {
      const res = await addMaintenanceMain({
        Type: this.addForm.Type,
        Content: this.addForm.Content
      })
      // console.log('新增公司官网----', res);
      if (res.Code === 200) {
        this.$msg.success(res.Message)
        this.fnGetMaintenanceMain()
      }
    }
  }

}
</script>

<style scoped lang="less">
.addForm_box {
  margin-top: 50px;
  width: 600px;
}
</style>
